
import { ref, onMounted } from "vue";
import Logo from "@/components/Logo.vue";
import PortfolioItem from "@/components/PortfolioItem.vue";

export default {
  name: "Portfolio",
  components: {
    Logo,
    PortfolioItem,
  },

  methods: {
    getAnimationDelay: function (index: number) {
      index = index + 2;
      return index * 0.15 + "s";
    },
  },

  setup() {
    const portfolioSection = ref(null);
    const header = ref(null);

    onMounted(() => {
      portfolioSection.value.addEventListener("scroll", () => {
        if (portfolioSection.value.scrollTop > 5) {
          header.value.classList.add("shadow");
        } else {
          header.value.classList.remove("shadow");
        }
      });
    });

    return {
      portfolioSection,
      header,
    };
  },

  data() {
    return {
      portfolioCategories: [
        {
          title: "Websites",
          id: 1,
          items: [
            {
              id: 8,
              title: "OTDRShop.nl",
              link: "https://www.otdrshop.nl",
              image: "portfolio/otdrshop.png",
              tags: ["design", "development", "fotografie"],
              modal: false,
            },
            {
              id: 7,
              title: "Baiyulan.nl",
              link: "https://baiyulan.nl",
              image: "portfolio/baiyulan-portfolio.jpg",
              tags: ["design", "development"],
              modal: false,
            },
            {
              id: 6,
              title: "InfraTEL.nl",
              link: "https://www.infratel.nl",
              image: "portfolio/infratel.jpg",
              tags: ["design", "development", "fotografie"],
              modal: false,
            },
            {
              id: 2,
              title: "Legisactio.nl",
              link: "https://www.legisactio.nl",
              image: "portfolio/lea.jpg",
              tags: ["design", "development"],
              modal: false,
            },
            {
              id: 1,
              title: "Theaterhart.com",
              link: "https://www.theaterhart.com",
              image: "portfolio/thh.jpg",
              tags: ["design", "development"],
              modal: false,
            },
            {
              id: 5,
              title: 'Theaterhart: interactief lesprogramma "Loser!"',
              link: "",
              image: "portfolio/thh-loser.jpg",
              tags: ["design", "development"],
              modal: true,
              modal_text:
                "Loser! is speciaal gemaakt voor groep 6,7 en 8, primair onderwijs. Het is een digitaal lesprogramma, waarbij de leerkracht met 1 druk op de knop samen met de klas pesten in de klas bespreekbaar kan maken en (digitaal) pesten kan stoppen. <br/><br/> Meer informatie? Check <a href='https://theaterhart.com/loser' class='btn text' target='blank'>theaterhart.com/loser</a>",
            },
            {
              id: 3,
              title: "Theblueworld.nl",
              link: "https://www.theblueworld.nl",
              image: "portfolio/tbw.jpg",
              tags: ["development"],
              modal: false,
            },
          ],
        },
        {
          title: "Fotografie",
          id: 3,
          items: [
            {
              id: 56,
              title: "Macro fotografie",
              link: "",
              image: "portfolio/macro2.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 55,
              title: "Familieshoot",
              link: "",
              image: "portfolio/frs5.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/frs1.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/frs2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/frs3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 4,
                  title: "",
                  link: "",
                  image: "portfolio/frs4.jpg",
                  modal: true,
                  imageMode: 'full'
                },

              ]
            },
            {
              id: 54,
              title: "Familieshoot",
              link: "",
              image: "portfolio/fr1.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/fr2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/fr3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/fr4.jpg",
                  modal: true,
                  imageMode: 'full'
                },

              ]
            },
            {
              id: 53,
              title: "Familiefeest",
              link: "",
              image: "portfolio/ml3.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/ml2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/ml1.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/ml4.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/ml5.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 56,
              title: "Winterconcert '23 O.K.K. Streefkerk",
              link: "",
              image: "portfolio/wk3.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/wk1.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/wk2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 52,
              title: "Rotterdam by night",
              link: "",
              image: "portfolio/rotterdam.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/rotterdam2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/rotterdam3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/rotterdam4.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 4,
                  title: "",
                  link: "",
                  image: "portfolio/rotterdam5.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 51,
              title: "A Blue Gospel Christmas",
              link: "",
              image: "portfolio/abgc1.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/abgc2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/abgc3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/abgc4.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 4,
                  title: "",
                  link: "",
                  image: "portfolio/abgc5.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 5,
                  title: "",
                  link: "",
                  image: "portfolio/abgc6.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 6,
                  title: "",
                  link: "",
                  image: "portfolio/abgc7.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 7,
                  title: "",
                  link: "",
                  image: "portfolio/abgc8.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 8,
                  title: "",
                  link: "",
                  image: "portfolio/abgc9.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 9,
                  title: "",
                  link: "",
                  image: "portfolio/abgc10.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 10,
                  title: "",
                  link: "",
                  image: "portfolio/abgc11.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 99,
              title: "Macro fotografie",
              link: "",
              image: "portfolio/macro1.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 50,
              title: "Classic Cars",
              link: "",
              image: "portfolio/elvis-fanday-85.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-86.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-87.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-88.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 4,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-89.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 50,
              title: "The Elvis Presley Show",
              link: "",
              image: "portfolio/elvis-fanday-82.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-83.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/elvis-fanday-84.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 50,
              title: "A5 Club Holland meeting 2/2",
              link: "",
              image: "portfolio/audi-event5.jpg",
              tags: ["fotografie", "design"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/audi-event4.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/audi-event6.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 50,
              title: "A5 Club Holland meeting 1/2",
              link: "",
              image: "portfolio/audi-event1.jpg",
              tags: ["fotografie", "design"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/audi-event2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/audi-event3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 49,
              title: "Geregistreerd partnerschap",
              link: "",
              image: "portfolio/gereg2.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/gereg5.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/gereg3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/gereg4.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/gereg1.jpg",
                  modal: true,
                  imageMode: 'full'
                }
              ]
            },
            {
              id: 48,
              title: "Architectuur in Utrecht",
              link: "",
              image: "portfolio/arch1.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/arch2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/arch3.jpg",
                  modal: true,
                  imageMode: 'full'
                }
              ]
            },
            {
              id: 47,
              title: "Audi A5",
              link: "",
              image: "portfolio/audi1.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/audi2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/audi3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/audi4.jpg",
                  modal: true,
                  imageMode: 'full'
                }
              ]
            },
            {
              id: 46,
              title: "Familiefeest",
              link: "",
              image: "portfolio/ff4.png",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/ff1.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/ff3.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 3,
                  title: "",
                  link: "",
                  image: "portfolio/ff2.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 4,
                  title: "",
                  link: "",
                  image: "portfolio/ff5.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 5,
                  title: "",
                  link: "",
                  image: "portfolio/ff6.jpg",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 45,
              title: "Familieportret",
              link: "",
              image: "portfolio/fp5.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 44,
              title: "Familieportret",
              link: "",
              image: "portfolio/fp4.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 43,
              title: "Familieportret",
              link: "",
              image: "portfolio/fp3.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 42,
              title: "Familieportret",
              link: "",
              image: "portfolio/fp2.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 41,
              title: "Familieportret",
              link: "",
              image: "portfolio/fp.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 40,
              title: "BMW 3 Serie",
              link: "",
              image: "portfolio/bmw3-2.png",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "Interieur",
                  link: "",
                  image: "portfolio/bmw3-3.png",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "Close-up",
                  link: "",
                  image: "portfolio/bmw3-1.png",
                  modal: true,
                  imageMode: 'full'
                },
              ]
            },
            {
              id: 34,
              title: "Fotoshoot baby-aankondiging",
              link: "",
              image: "portfolio/rs_baby3.jpg",
              tags: ["fotografie"],
              modal: true,
              imageMode: 'full',
              stack: [
                {
                  id: 1,
                  title: "",
                  link: "",
                  image: "portfolio/rs_baby1.jpg",
                  modal: true,
                  imageMode: 'full'
                },
                {
                  id: 2,
                  title: "",
                  link: "",
                  image: "portfolio/rs_baby2.jpg",
                  modal: true,
                },
              ]
            },
            {
              id: 27,
              title: "Fotoshoot oude style",
              link: "",
              image: "portfolio/shoot-3.jpg",
              tags: ["fotografie"],
              modal: true,
              stack: [
                {
                  id: 26,
                  link: "",
                  image: "portfolio/shoot-1.jpg",
                  tags: ["fotografie"],
                  modal: true,
                },
                {
                  id: 25,
                  link: "",
                  image: "portfolio/shoot-2.jpg",
                  tags: ["fotografie"],
                  modal: true,
                },
                {
                  id: 24,
                  link: "",
                  image: "portfolio/shoot-4.jpg",
                  tags: ["fotografie"],
                  modal: true,
                },
                {
                  id: 23,
                  link: "",
                  image: "portfolio/shoot-5.jpg",
                  tags: ["fotografie"],
                  modal: true,
                },
              ]
            },
            
            {
              id: 36,
              title: "Golden-Hour #2",
              link: "",
              image: "portfolio/sunset-donk.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 35,
              title: "Golden-Hour #1",
              link: "",
              image: "portfolio/sunset-streefkerk.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 34,
              title: "Oude Donkse Brug",
              link: "",
              image: "portfolio/oude-donk.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 31,
              title: "Dodenherdenking - Streefkerk",
              link: "",
              image: "portfolio/dodenherdenking-1.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 30,
              title: "Muziekvereniging O.K.K. Streefkerk",
              link: "",
              image: "portfolio/muziekvereniging-okk.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 29,
              title: "Koningsdag - Streefkerk",
              link: "",
              image: "portfolio/koningsdag.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 28,
              title: "Fiets 'm erin! - Streefkerk",
              link: "",
              image: "portfolio/fietsmderin.jpg",
              tags: ["fotografie", "video", "dronefotografie"],
              modal: true,
            },
            {
              id: 22,
              title: "Ochtenddauw",
              link: "",
              image: "portfolio/dew.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 21,
              title: "Erä-Susi Huskies 🇫🇮",
              link: "",
              image: "portfolio/husky.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 20,
              title: "Lago di Garda 🇮🇹",
              link: "",
              image: "portfolio/drone-lago-di-garda-2.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 19,
              title: "Lago di Garda b/w 🇮🇹",
              link: "",
              image: "portfolio/drone-lago-di-garda-1.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 18,
              title: "Stuwcomplex Hagestein",
              link: "",
              image: "portfolio/drone-stuwcomplex-hagestein.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 17,
              title: "Sunny Streefkerk",
              link: "",
              image: "portfolio/drone-streefkerk-sunny.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 16,
              title: "Icy Streefkerk",
              link: "",
              image: "portfolio/drone-streefkerk-snow.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 15,
              title: "Kirche Sankt Nikolaus 🇦🇹",
              link: "",
              image: "portfolio/drone-at-damuls.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 14,
              title: "Nieuwegein",
              link: "",
              image: "portfolio/drone-nieuwegein.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 1,
              title: "Kerkstraat, Schoonhoven",
              link: "",
              image: "portfolio/kerkstraat.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 2,
              title: "Koestraat, Schoonhoven",
              link: "",
              image: "portfolio/koestraat.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 4,
              title: "Zijdebrug",
              link: "",
              image: "portfolio/zijdebrug.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 5,
              title: "Springer Park",
              link: "",
              image: "portfolio/springer-park.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 12,
              title: "Muiden",
              link: "",
              image: "portfolio/muiden.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 3,
              title: "Malteser koffie",
              link: "",
              image: "portfolio/centrum-coffee-closeup.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 3,
              title: "Cocktails close-up",
              link: "",
              image: "portfolio/centrum-cocktails-closeup.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 7,
              title: "Kempervenne",
              link: "",
              image: "portfolio/kempervenne.jpg",
              tags: ["dronefotografie"],
              modal: true,
            },
            {
              id: 11,
              title: "Vlist",
              link: "",
              image: "portfolio/vlist.jpg",
              tags: ["fotografie"],
              modal: true,
            },
            {
              id: 13,
              title: "Langeland",
              link: "",
              image: "portfolio/langeland.jpg",
              tags: ["fotografie"],
              modal: true,
            },
          ],
        },
        {
          title: "Design",
          id: 2,
          items: [
            {
              id: 11,
              title: "Oranjeplein - Oranje vereniging Streefkerk",
              link: "",
              image: "portfolio/oranjeplein_A5_v2.jpg",
              tags: ["design"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 10,
              title: "Fiets 'm erin - Oranje vereniging Streefkerk",
              link: "",
              image: "portfolio/Fietsmerin_A5_v2.jpg",
              tags: ["design"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 9,
              title: "The Elvis Presley Show collage",
              link: "",
              image: "portfolio/elvis-fandy-banner.jpg",
              tags: ["design", "fotografie"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 8,
              title: "Giftcard - My Skin Improvement",
              link: "",
              image: "portfolio/msi-giftcard-mockup.jpg",
              tags: ["design"],
              modal: true,
              modalImage: "portfolio/msi-giftcard-modal.jpg",
              imageMode: 'full',
            },
            {
              id: 7,
              title: "Flyer algemeen - InfraTEL",
              link: "",
              image: "portfolio/infratel-flyer.jpg",
              tags: ["design", "fotografie"],
              modal: true,
              imageMode: 'full',
            },
            {
              id: 15,
              title: "Roll-up banner - InfraTEL",
              link: "",
              image: "portfolio/it-rollupbanner.jpg",
              tags: ["design", "fotografie"],
              modal: true,
              imageMode: 'full',
            },

            {
              id: 5,
              title: "Placemat - 't Centrum",
              link: "",
              image: "portfolio/centrum-placemat-mockup.jpg",
              tags: ["design", "fotografie"],
              modal: true,
              modalImage: "portfolio/centrum-placemat.jpg",
            },
            {
              id: 5,
              title: "Cadeaubon - 't Centrum",
              link: "",
              image: "portfolio/centrum-cadeaubon-mockup.jpg",
              tags: ["design", "fotografie"],
              modal: true,
              modalImage: "portfolio/centrum-cadeaubon.jpg",
            },
            {
              id: 1,
              title: "Cocktailkaart - 't Centrum",
              link: "",
              image: "portfolio/centrum-cocktailkaart.png",
              tags: ["design", "fotografie"],
              modal: true,
              modalImage: "portfolio/centrum-cocktailkaart-full.jpg"
            },
            {
              id: 2,
              title: "Koffie kaart - 't Centrum",
              link: "",
              image: "portfolio/centrum-koffiekaart.png",
              tags: ["design", "fotografie"],
              modal: true,
              modalImage: "portfolio/centrum-koffiekaart-full.jpg"
            }
          ],
        },
        {
          title: "Video",
          id: 4,
          items: [
            {
              id: 1,
              title: "Fiets 'm erin! 2024",
              link: "",
              image: "portfolio/fietsmerin-video-2.jpg",
              tags: ["dronefotografie", "video", "design"],
              modal: true,
              modal_text:
                "<a href='https://www.youtube.com/watch?v=hWiTR0LIt6Q&t=441s' class='btn text' target='blank'>Bekijk deze video op YouTube</a>",
            },
            {
              id: 1,
              title: "Fiets 'm erin! 2023",
              link: "",
              image: "portfolio/fietsmerin-video.jpg",
              tags: ["dronefotografie", "video", "fotografie", "design"],
              modal: true,
              modal_text:
                "<a href='https://www.youtube.com/watch?v=T3JMaEULim4' class='btn text' target='blank'>Bekijk deze video op YouTube</a>",
            }
          ],
        },
      ],
    };
  },
};
